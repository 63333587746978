import React from 'react'
import MarkdownJSX from 'markdown-to-jsx'

const MarkdownLink = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

const Markdown = ({ className, content }) => {
  return (
    <div className={className}>
      <MarkdownJSX
        options={{
          overrides: {
            a: { component: MarkdownLink },
          },
        }}
      >
        {content}
      </MarkdownJSX>
    </div>
  )
}

export default Markdown
