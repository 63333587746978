import React from "react";

const CardNav = () => {
  return (
    <nav className="card-nav mb-5">
      <div className="app-container">
        <a href="#best-travel">
          <i className="fal fa-island-tropical" />
          <span>Travel</span>
        </a>
        <a href="#best-cash-back">
          <i className="fal fa-sack-dollar" />
          <span>Cash Back</span>
        </a>
        <a href="#best-no-annual-fee">
          <i className="fal fa-ban" />
          <span>No Annual Fee</span>
        </a>
        <a href="#best-rewards">
          <i className="fal fa-treasure-chest" />
          <span>Rewards</span>
        </a>
        <a href="#best-low-interest">
          <i className="fal fa-percent" />
          <span>Low Interest</span>
        </a>
        <a href="#best-poor-credit">
          <i className="fal fa-chart-line-down" />
          <span>Poor Credit</span>
        </a>
        <a href="#best-business">
          <i className="fal fa-suitcase" />
          <span>Business</span>
        </a>
        <a href="#best-student">
          <i className="fal fa-graduation-cap" />
          <span>Student</span>
        </a>
      </div>
    </nav>
  );
};

export default CardNav;
