import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

const AdDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className="ad-disclosure">
      <DropdownToggle color="link" className="ad-disclosure-link">
        Ad Disclosure
      </DropdownToggle>
      <DropdownMenu className="ad-disclosure-dropdown" right>
        <h5 className="text-primary text-extra-bold">Advertiser Disclosure</h5>
        <p>
          Some offers appearing on this site are from paying advertisers.
          Advertising may impact how and where products appear on this site
          (including, for example, the order in which they appear). Creditminds
          does not feature all financial companies or products but strives to
          present a wide variety of offers.
        </p>
      </DropdownMenu>
    </Dropdown>
  );
}

export default AdDisclosure;
