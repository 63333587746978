import React from "react";

import OfferCard from "./OfferCard";

const CardList = ({ offers }) => {
  return (
    <>
      {offers.map(offer => (
        <OfferCard offer={offer} key={offer.id} />
      ))}
    </>
  );
};

export default CardList;
