import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Row, Col } from "reactstrap"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import CardNav from "../components/CardNav"
import AdDisclosure from "../components/AdDisclosure"
import BestCardsSection from "../components/BestCardsSection"

const IndexPage = ({ data }) => {
  useEffect(() => {
    const html = document.querySelector("html")
    html.style.scrollBehavior = "smooth"
    return () => {
      html.style.scrollBehavior = ""
    }
  }, []);

  const today = new Date();
  const currentMonth = today.toLocaleDateString('en-US', { month: 'long' });
  const currentYear = today.getFullYear();

  return (
    <Layout>
      <Seo title={`Compare and find the best credit cards`} />
      <div className="app-container">
        <AdDisclosure />

        <Row className="mb-4 mt-5">
          <Col
            sm={{ size: 12, offset: 0 }}
            md={{ size: 8, offset: 2 }}
            className="text-center mt-3"
          >
            <h6 className="text-kicker">{currentMonth} {currentYear}</h6>
            <h1>Best Credit Cards</h1>
            <p className="text-lead-1">
              We've compared the credit cards offers from all the major issuers
              to find the best cards that fit your needs.
            </p>
          </Col>
        </Row>
        <CardNav />

        <BestCardsSection
          name="travel"
          title="Best Cards for Travel"
          description="These cards offer great travel perks. Look out for big mileage bonus offers and benefits like free checked bags and lounge access."
          offers={data.creditminds.bestCards.TRAVEL}
        />
        <BestCardsSection
          name="cash-back"
          title="Best Cash Back Cards"
          description="Cash back cards pay you back for the things you buy every day. Cards may pay a flat rate on all purchases or higher rates for specific purchase categories."
          offers={data.creditminds.bestCards.CASH_BACK}
        />
        <BestCardsSection
          name="no-annual-fee"
          title="Best Cards with No Annual Fee"
          description="You don't always have to pay a yearly fee to get a great card with great benefits. These cards offer amazing rewards without the annual fee."
          offers={data.creditminds.bestCards.NO_ANNUAL_FEE}
        />
        <BestCardsSection
          name="rewards"
          title="Best Cards for Rewards"
          description="Rewards cards offer great benefits like airline miles, travel points or cash back for the things you purchase everyday."
          offers={data.creditminds.bestCards.REWARDS}
        />
        <BestCardsSection
          name="low-interest"
          title="Best Cards for Low Interest"
          description="These cards have a low regular purchase APR and many offer a introductory interest rate of 0% on purchases and balance transfers."
          offers={data.creditminds.bestCards.LOW_INTEREST}
        />
        <BestCardsSection
          name="poor-credit"
          title="Best Cards for Poor Credit"
          description="For those with bad or no credit, these cards offer a way to help rebuild your credit history. Be careful to keep a low balance and always pay on time."
          offers={data.creditminds.bestCards.POOR_CREDIT}
        />
        <BestCardsSection
          name="business"
          title="Best Cards for Business"
          description="Business credit cards offer great benefits for business owners on everyday expenses and can even earn rewards on your employee's purchase."
          offers={data.creditminds.bestCards.BUSINESS}
        />
        <BestCardsSection
          name="student"
          title="Best Cards for Students"
          description="Student cards are a great way for those in school or who have recently graduated to start building and establishing a good credit history."
          offers={data.creditminds.bestCards.STUDENT}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query IndexPageQuery {
    creditminds {
      bestCards {
        CASH_BACK {
          ...cardFields
        }
        TRAVEL {
          ...cardFields
        }
        NO_ANNUAL_FEE {
          ...cardFields
        }
        REWARDS {
          ...cardFields
        }
        LOW_INTEREST {
          ...cardFields
        }
        POOR_CREDIT {
          ...cardFields
        }
        BUSINESS {
          ...cardFields
        }
        STUDENT {
          ...cardFields
        }
      }
    }
  }

  fragment cardFields on Creditminds_Card {
    id
    slug
    enabled
    issuer
    type
    network
    name
    description
    offerDetails
    rewardsDetails
    legalDisclaimer
    editorRating
    minCreditNeeded
    imageUrl
    applyUrl
    termsUrl
    minPurchaseApr
    maxPurchaseApr
    purchaseApr
    introPurchaseApr
    introTransferApr
    transferFees
    annualFees
    minDeposit
    cashAdvanceApr
    cashAdvanceFee
    latePaymentFee
    foreignTransactionFee
    overLimitFee
    purchaseGracePeriod
    balanceTransferGracePeriod
    cashAdvanceGracePeriod
    bonusOffer
    bonusOfferDescription
    rewards
    rewardsDescription
    pointsPerDollar
    milesPerDollar
    cashBackGas
    cashBackGroceries
    cashBackOther
    tags {
      id
      label
    }
  }
`

export default IndexPage
