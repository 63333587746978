import React from 'react'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'

const InfoPopOver = ({ id, children }) => {
  return (
    <span className="info-popover">
      <button id={id} type="button" className="info-popover-button">
        <i className="fal fa-info-circle" />
      </button>
      <UncontrolledPopover
        fade={false}
        flip={true}
        trigger="focus"
        placement="top"
        target={id}
      >
        <PopoverBody>{children}</PopoverBody>
      </UncontrolledPopover>
    </span>
  )
}

export default InfoPopOver
