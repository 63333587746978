import React from 'react';
import { startCase } from 'lodash';
import { Row, Col } from "reactstrap";

// const SCORES = {
//   BAD: 0,
//   POOR: 1,
//   FAIR: 2,
//   GOOD: 3,
//   EXCELLENT: 4,
//   LIMITED: 5,
// };

const CreditRatingGauge = ({ creditScoreNeeded, tags }) => {

  // console.log(888, tags);

  const mappedTags = tags.map(t => t.id);
  const active = {
    bad: mappedTags.includes('bad-credit'),
    fair: mappedTags.includes('fair-credit'),
    good: mappedTags.includes('good-credit'),
    excellent: mappedTags.includes('excellent-credit'),
    limited: mappedTags.includes('limited-credit'),
  }

  const list = Object.entries(active).filter(([_, value]) => value).map(([label]) => startCase(label));
  const lastItem = list.pop();
  let desc;

  if (list.length > 0) {
    desc = `${list.join(', ')} or ${lastItem} Credit`;
  } else {
    desc = `${lastItem} Credit`;
  }

  return (
    <Row className="pb-3">
      <Col xs={5} sm={6} md={5} lg={4}>
        <svg className="credit-score-gauge" width="100%" height="75" viewBox="0 0 150 75" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="nonzero" fill="none">
            <path className={!active.bad ? 'disabled' : ''} d="M0,76 C0,38.954754 29.7925458,-5.39358138 84.5329035,1.48088786 L78.9233837,38.7 C50.3526211,37.4154677 37.5,59.2916849 37.5,76 L0,76 Z" fill="#E97855" />
            <path className={!active.fair ? 'disabled' : ''} d="M87.0081351,1.86115942 C103.682512,4.44334116 116.987842,13.480287 122.29145,17.7890152 L98.645725,46.8945076 C95.993921,44.7401435 88.9268865,39.9325415 80.2330412,38.9305797 L87.0081351,1.86115942 Z" fill="#FFB961" />
            <path className={!active.good ? 'disabled' : ''} d="M124.294175,19.4749235 C129.44418,23.9661202 133.95625,29.1400603 137.705145,34.8531316 L106.352573,55.4265658 C104.478125,52.5700301 102.22209,49.9830601 99.6470877,47.7374618 L124.294175,19.4749235 Z" fill="#85CB82" />
            <path className={!active.excellent ? 'disabled' : ''} d="M139.102952,37.0665751 C146.230689,48.802224 150,62.2693738 150,76 L112.5,76 C112.5,69.1346869 110.615345,62.401112 107.051476,56.5332876 L139.102952,37.0665751 Z" fill="#4F9E6D" />
          </g>
        </svg>
      </Col>
      <Col xs={7} sm={6} md={7} lg={8}>
        <div className="mt-2">
          Best for those with<br />
          <strong>{desc}</strong>
        </div>
      </Col>

    </Row>
  );
}

export default CreditRatingGauge;
