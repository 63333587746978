import React from "react"
import { Row, Col } from "reactstrap"

import CardList from './CardList';

const BestCardsSection = ({ name, title, description, offers }) => {
  return (
    <section className="best-cards-section" id={`best-${name}`}>
      <hr />
      <Row>
        <Col
          sm={{ size: 12, offset: 0 }}
          md={{ size: 8, offset: 2 }}
          className="text-center mt-3 mb-5"
        >
          <h2>{title}</h2>
          <p className="text-lead-2">{description}</p>
        </Col>
      </Row>
      <CardList offers={offers} />
    </section>
  )
}

export default BestCardsSection
