import React, { useState } from "react"
import { Card, CardBody, Row, Col, Collapse, Table } from "reactstrap"
import { isNil } from "lodash"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import StarRating from "./StarRating"
import Markdown from "./Markdown"
import CreditRatingGauge from "./CreditRatingGauge"
import InfoPopOver from "./InfoPopOver"

const OfferCard = ({ offer }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Card className="offer">
      <CardBody>
        <Row>
          <Col lg="9" md="8">
            <OutboundLink
              href={offer.applyUrl}
              eventLabel={offer.slug}
              eventCategory="Outbound Affiliate Link"
              className="text-body"
              target="blank"
            >
              <h4 className="offer-title">{offer.name}</h4>
            </OutboundLink>
            <p className="offer-issuer">By {offer.issuer}</p>
            <Row>
              <Col sm="12" md="4" lg="4">
                <div>
                  <OutboundLink
                    href={offer.applyUrl}
                    eventLabel={offer.slug}
                    eventCategory="Outbound Affiliate Link"
                    target="blank"
                  >
                    <img
                      className="offer-image"
                      src={offer.imageUrl}
                      alt={offer.name}
                    />
                  </OutboundLink>
                </div>
                {offer.termsUrl && (
                  <p className="offer-terms-link">
                    <OutboundLink href={offer.termsUrl} target="blank">
                      Rates and Fees
                    </OutboundLink>
                  </p>
                )}
              </Col>
              <Col>
                <Table className="offer-table">
                  <tbody>
                    {!isNil(offer.rewards) && (
                      <tr>
                        <th>Rewards Rate</th>
                        <td>
                          {offer.rewards}
                          <InfoPopOver id={`reward-popover-${offer.slug}`}>
                            {offer.rewardsDescription}
                          </InfoPopOver>
                        </td>
                      </tr>
                    )}
                    {!isNil(offer.bonusOffer) && (
                      <tr>
                        <th>Bonus Offer</th>
                        <td>
                          {offer.bonusOffer}
                          <InfoPopOver id={`bonus-popover-${offer.slug}`}>
                            {offer.bonusOfferDescription}
                          </InfoPopOver>
                        </td>
                      </tr>
                    )}
                    {!isNil(offer.introPurchaseApr) && (
                      <tr>
                        <th>Purchase Intro APR</th>
                        <td>{offer.introPurchaseApr}</td>
                      </tr>
                    )}
                    {!isNil(offer.introTransferApr) && (
                      <tr>
                        <th>Transfer Intro APR</th>
                        <td>{offer.introTransferApr}</td>
                      </tr>
                    )}
                    {!isNil(offer.purchaseApr) && (
                      <tr>
                        <th>Regular APR</th>
                        <td>{offer.purchaseApr}</td>
                      </tr>
                    )}
                    {!isNil(offer.annualFees) && (
                      <tr>
                        <th>Annual Fee</th>
                        <td>{offer.annualFees}</td>
                      </tr>
                    )}
                    {!isNil(offer.tranferFees) && (
                      <tr>
                        <th>Balance Transfer Fees</th>
                        <td>{offer.tranferFees}</td>
                      </tr>
                    )}
                    {!isNil(offer.minDeposit) && (
                      <tr>
                        <th>Minimum Deposit</th>
                        <td>{offer.minDeposit}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>

          <Col lg="3" md="4">
            <div>
              {offer.editorRating && (
                <div className="offer-rating">
                  <h6>Editor Rating</h6>

                  <StarRating rating={offer.editorRating} />
                  {Number(offer.editorRating).toFixed(1)}
                </div>
              )}
              <div className="offer-apply">
                <OutboundLink
                  href={offer.applyUrl}
                  eventLabel={offer.slug}
                  eventCategory="Outbound Affiliate Link"
                  target="blank"
                  className="btn-block apply-btn"
                >
                  <i className="fa fa-lock mr-2" />
                  Apply Now
                </OutboundLink>
                <p>on {offer.issuer}&apos;s secure site</p>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
      <Collapse isOpen={isOpen}>
        <CardBody className="offer-more-details">
          <Row>
            <Col md="6">
              {offer.rewardsDetails && (
                <section className="offer-section">
                  <h5 className="offer-section-title">Rewards Details</h5>
                  <div>
                    <Markdown
                      className="offer-markdown"
                      content={offer.rewardsDetails}
                    />
                  </div>
                </section>
              )}
              {offer.offerDetails && (
                <section className="offer-section">
                  <h5 className="offer-section-title">Additional Details</h5>
                  <div>
                    <Markdown
                      className="offer-markdown"
                      content={offer.offerDetails}
                    />
                  </div>
                </section>
              )}
            </Col>
            <Col md="6" className="pl-md-5">
              <section className="offer-section">
                <h5 className="offer-section-title">Minimum Credit Rating</h5>
                <CreditRatingGauge
                  creditScoreNeeded={offer.minCreditNeeded}
                  tags={offer.tags}
                />
              </section>
              <section className="offer-section">
                <h5 className="offer-section-title">Additional Information</h5>
                <Table className="offer-table">
                  <tbody>
                    {!isNil(offer.latePaymentFee) && (
                      <tr>
                        <th>Late Payment Fee</th>
                        <td>{offer.latePaymentFee}</td>
                      </tr>
                    )}
                    {!isNil(offer.overLimitFee) && (
                      <tr>
                        <th>OverLimit Fee</th>
                        <td>{offer.overLimitFee}</td>
                      </tr>
                    )}
                    {!isNil(offer.foreignTransactionFee) && (
                      <tr>
                        <th>Foreign Transaction Fee</th>
                        <td>{offer.foreignTransactionFee}</td>
                      </tr>
                    )}
                    {!isNil(offer.cashAdvanceApr) && (
                      <tr>
                        <th>Cash Advance APR</th>
                        <td>{offer.cashAdvanceApr}</td>
                      </tr>
                    )}
                    {!isNil(offer.cashAdvanceFee) && (
                      <tr>
                        <th>Cash Advance Fee</th>
                        <td>{offer.cashAdvanceFee}</td>
                      </tr>
                    )}
                    {!isNil(offer.purchaseGracePeriod) && (
                      <tr>
                        <th>Purchase Grace Period</th>
                        <td>{offer.purchaseGracePeriod}</td>
                      </tr>
                    )}
                    {!isNil(offer.balanceTransferGracePeriod) && (
                      <tr>
                        <th>Balance Transfer Grace Period</th>
                        <td>{offer.balanceTransferGracePeriod}</td>
                      </tr>
                    )}
                    {!isNil(offer.cashAdvanceGracePeriod) && (
                      <tr>
                        <th>Cash Advance Grace Period</th>
                        <td>{offer.cashAdvanceGracePeriod}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </section>
            </Col>
          </Row>
        </CardBody>
      </Collapse>
      {/* eslint-disable-next-line */}
      <div
        type="button"
        className="offer-show-more"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {!isOpen && (
          <>
            Show Details{" "}
            <small>
              <i className="fa fa-chevron-down ml-1" />
            </small>
          </>
        )}
        {isOpen && (
          <>
            Hide Details{" "}
            <small>
              <i className="fa fa-chevron-up ml-1" />
            </small>
          </>
        )}
      </div>
    </Card>
  )
}

export default OfferCard
