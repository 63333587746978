import React from 'react';

const StarRating = ({ rating }) => {

  const percentage = (rating / 5) * 100;
  const stars = [0,0,0,0,0];

  return (
    <div className="star-ratings">
      <div className="star-ratings-top" style={{ width: `${percentage}%` }}>
        {stars.map((star, i) => <i key={i} className="fa fa-check-square" />)}
      </div>
      <div className="star-ratings-bottom">
        {stars.map((star, i) => <i key={i} className="fa fa-check-square" />)}
      </div>
    </div>
  )
}

export default StarRating;
